<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="banner">
      <v-img class="img" v-if="ban" :src="ban" cover alt=""/>
    </div>
    <div class="infocard d-flex flex-lg-row flex-column">
      <div class="d-flex flex-lg-row flex-column infoAvat">
        <div class="d-flex flex-lg-column align-start avat">
          <v-avatar
              size="191"
              style="background-color: #6f58d9; border: solid 8px #ffffff"
          >
            <v-img :src="src" alt=""/>
          </v-avatar>

           <div class="unflowbox" v-if="!isFollowd&& !isSelf">
            <v-btn class="px-lg-2 unflow" @click="unflowBtn">{{$t('follow')}}</v-btn>
          </div> 
          <v-btn
            v-if="isFollowd"
            class="px-lg-2 isPcBtn flowed"
            @click="flowedBtn"
            >{{ $t('avatarAvatarInfoSubscribed') }}</v-btn
          >
        </div>       
        <div class="d-flex flex-column infos ml-lg-8 mt-lg-12 mt-16">
          <div class="showtip d-flex flex-lg-row justify-space-between">
            <span class="name" style="font-weight: bold">{{
                pageInfo.name
              }}</span>
               <img
              
              class="hovershow mt-lg-5 mt-5"
              src="@/assets/icons/icon_complaint.png"
              alt=""
              width="24px"
              height="24px"
			      style="cursor: pointer;"
              @click="accPlaintBtn"
              @mouseenter="moused()"   @mouseleave="leave()"
            />
            <div class="explains d-flex flex-row align-center" v-if="isshow">
               	 <span>Report</span>
             </div>
          </div>
          <div class="no_copy">         
            <span v-if="!isMobile" class="No">{{ bscAddress }}</span>
            <span v-if="isMobile" class="No">{{
                bscAddress | didfilter
              }}</span>
            <v-img
                class="copy"
                :class="{ click: isCopy }"
                src="@/assets/icons/icon_copy.png"
                @click="onCopyClick"
            />
          </div>
              <div class="icon_url " style="margin-top:-3px;">
            <!-- <div class="icon-box  mt-1 " v-if="pageInfo.instagram && pageInfo.instagram!='null'">
               <a :href="Instagram" target="_blank">
                 <img
                class="mb-n2"
                style="margin-left: 0"
                src="@/assets/icons/icon_Instagram.png"
                alt=""
              />
              <span style="color:#270645">{{ decodeURIComponent(pageInfo.instagram) }}</span>
              </a>
             
            </div> -->
            <div class="icon-box  mt-1 media" v-if="pageInfo.twitter && pageInfo.twitter!='null' && pageInfo.twitterVerify">
              <a :href="Twitter" target="_blank">
                <img class="mr-2 mb-n2 " src="@/assets/icons/icon_twitter1.png" alt="" />
                <span class="text-caption">{{ decodeURIComponent(pageInfo.twitterName) }}</span>
                     <img
               v-if="pageInfo.twitterVerify"
              class="mr-2 mb-n1"
              style="margin-left: 4px;width:16px;height:16px;"
              src="@/assets/icons/icon_verified.png"
              alt=""
            />
              </a>
            </div>
            <!-- <div class="icon-box  mt-1 media" v-if="pageInfo.youtube && pageInfo.youtube!='null'">
              <a :href="Youtube" target="_blank">
                <img class="mr-2 mb-n2" src="@/assets/icons/icon_youtube.png" alt="" />
                <span class="text-caption">{{ decodeURIComponent(pageInfo.youtube ) }}</span>
              </a>
            </div>
            <div class="icon-box  mt-1 media"  v-if="pageInfo.homepage && pageInfo.homepage!='null'">
              <a :href="Homepage" target="_blank">
                <img class="mr-2 mb-n2" src="@/assets/icons/icon_site.png" alt="" />
                <span class="text-caption">{{  decodeURIComponent(pageInfo.homepage) }}</span>
              </a>

            </div> -->
          </div>
          <div class="d-flex flex-column">
            <div
                class="desc pading-gap mb-lg-3 mt-lg-3"
                v-html="pageInfo.description"
            ></div>
             <div class="d-flex flex-lg-row flex-column">
               <div class="d-flex flex-lg-row flex-column follow" >
                 <div class="">
                  <div class="num">{{ pageInfo.fansNumber}}</div>
                  <div class="sub">{{ $t("avatarAvatarInfoFans") }}</div>
              </div>
               <div class=" ml-6">
                  <div class="num">{{ pageInfo.followCount}}</div>
                  <div class="sub">{{ $t("fansSubscrible") }}</div>
                </div>
            </div>
              <div
                class="footer ml-lg-16 d-flex flex-row"
                v-if="pageInfo.fansNumber > 0"
              >
                <div class="avatar-name mt-lg-3">
                  {{ $t("avatarAvatarInfoFans") }}
                </div>
                <div class="mt-lg-2" style="position: relative; height: 30px">
                  <div
                    class="avatar"
                    :style="{ left: `${index * 18}px` }"
                    v-for="(info, index) of avatars"
                    :key="index"
                    @click="avatarClick"
                  >
                    <v-avatar size="28">
                      <v-img
                        v-if="info.src == null"
                        src="@/assets/default.png"
                        alt=""
                      />
                      <v-img v-else :src="info.src" alt="" />
                    </v-avatar>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="accountComplaint">
      <account-complaints
          @getOpen="openAccComp"
          :pageInfo="pageInfo"
      ></account-complaints>
    </v-dialog>
    <v-dialog fullscreen v-model="openLargemg">
      <large-img
          ref="largeDialog"
          v-if="openLargemg"
          :productPic="productPic"
          @getOpen="Open"
      >
      </large-img>
    </v-dialog>
    
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import AccountComplaints from "@/components/popup/accountComplaints.vue";

import {getFileSrc} from "@/utils/file";
import api from "@/api";
import LargeImg from "@/components/largeImg.vue";
import ScrollX from "@/components/scroll/ScrollX.vue";
import logoimg from "@/assets/default.png";
//
const AVATAR_KEY = "AVATAR";
const BANNER_KEY = "BANNER";
const SYNOPSIS_KEY = "SYNOPSIS";

export default {
  name: "headerPage",
  components: {AccountComplaints, LargeImg, ScrollX},
  data: () => ({
    isshow:false,
    src: "",
    ban: "",
    accountComplaint: false,
    avatars: [],
    pageInfo: {},
    isFollowd:false,
    isCopy: false,
    isSelf: false,
    Twitter: "",
    Youtube: "",
    Homepage: "",
    //展示图片
    imgList: [
      //接口获取的三张图
    ],
    openLargemg: false,
    productPic: "",
    joinTime: "",
    bscAddress: "",
       logoimg
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
    didfilter: function (value) {
      if (value && value.length > 24) {
        return value.substr(0, 12) + "..." + value.substr(-12);
      } else {
        return "";
      }
    },
  },
  mounted() {
    window.eventBus.$on("LangChange", this.onLangChange);
    this.getFans();
  },
  methods: {
    onLangChange() {
      this.avatars = [];
      this.imgList = [];

      this.getFans();
    },
    async isFollow(){
      let params = {
        userAddress: this.$route.query.did,
        fansAddress: this.$store.state.did,
      };
       let res = await api.home.isFollow(params);
       if(res.data == true){
         this.isFollowd = true

       }else{
          this.isFollowd= false
       }

    },
    moused() {
     			 this.isshow = true;
   			 },
   			 leave() {
     			 this.isshow = false;
				},

    async getFans() {
      let params = {
        address: this.$route.query.did,
      };
      let res = await api.home.loopringUserInfo(params);
      this.pageInfo = res.data;
   
        if (this.pageInfo.profilePhoto != null && this.pageInfo.profilePhoto != '') {
          this.src = await getFileSrc(AVATAR_KEY, this.pageInfo.profilePhoto);
        } else {
          this.src = logoimg;
        }
        if(res.data.fansInfoDtoList){
           let fanSrcList = res.data.fansInfoDtoList;
           let fansrc;
        fanSrcList.forEach(async (v) => {
          if (v.fansPhoto != null && v.fansPhoto !="") {
            fansrc = await getFileSrc(AVATAR_KEY, v.fansPhoto);
          }else{
            fansrc = logoimg;
          }
           this.avatars.push({ src: fansrc });
        });
        }

      
      if (res.data.address != null) {
        this.bscAddress = res.data.address;
      }

      if (this.pageInfo.joinTime != null) {
        let t = this.$t("otherpage_join");
        let reg = new RegExp("【N】", "g");
        this.joinTime = t.replace(reg, this.pageInfo.joinTime);
      }
      if (this.pageInfo.twitter != null) {
        let newtwitter = this.pageInfo.twitter.indexOf("http");
        if (newtwitter == 0) {
          this.Twitter = `${this.pageInfo.twitter}`;
        }
        if (newtwitter == -1) {
          this.Twitter = `https://${this.pageInfo.twitter}`;
        }
      }
      if (this.pageInfo.youtube != null) {
        let newyoutube = this.pageInfo.youtube.indexOf("http");
        if (newyoutube == 0) {
          this.Youtube = `${this.pageInfo.youtube}`;
        }
        if (newyoutube == -1) {
          this.Youtube = `https://${this.pageInfo.youtube}`;
        }
      }
      if (this.pageInfo.homepage != null) {
        let newhomepage = this.pageInfo.homepage.indexOf("http");
        if (newhomepage == 0) {
          this.Homepage = `${this.pageInfo.homepage}`;
        }
        if (newhomepage == -1) {
          this.Homepage = `https://${this.pageInfo.homepage}`;
        }
      }
      if (this.pageInfo.description)
        this.pageInfo.description = this.pageInfo.description.replace(/\n/g, "<br/>");
      this.$emit("getName", this.pageInfo.name);
      if (this.pageInfo.profilePhoto != null){
         this.src = await getFileSrc(AVATAR_KEY, this.pageInfo.profilePhoto);
      }else{
           this.src = logoimg;
      }
       
      if (this.pageInfo.background != null){
          this.ban = await getFileSrc(BANNER_KEY, this.pageInfo.background);
      }else{
        this.ban = logoimg;
      }
      

      this.isSelf = this.$store.state.did == this.pageInfo.address;
      this.isFollow();
    },
    async unflowBtn() {
     this.$gtag.event('个人主页-取消关注', { 'event_category': 'Click', 'event_label': '取消关注' })
      let params = {
        chainType: this.$store.state.chainType,
        userAddress: this.$route.query.did,
        fansAddress: this.$store.state.did,
      };
      let res = await api.home.addFollow(params);
      if (res.success === true) this.isFollowd = true;
       console.log("unflowBtn",this.pageInfo.followed)
    },
    async flowedBtn() {
      this.$gtag.event('个人主页-关注', { 'event_category': 'Click', 'event_label': '关注' })
      let params = {
        chainType: this.$store.state.chainType,
        userAddress: this.$route.query.did,
        fansAddress: this.$store.state.did,
      };
      let res = await api.home.removeFollow(params);
      if (res.success === true) this.isFollowd = false;
    },
    accPlaintBtn() {
       this.$gtag.event('个人主页-投诉', { 'event_category': 'Click', 'event_label': '投诉' })
      this.accountComplaint = true;
    },
    openAccComp(e) {
      this.accountComplaint = e;
    },
    openLargeImg(item) {
      this.productPic = item.src;
      this.openLargemg = true;
    },
    Open(e) {
      this.openLargemg = e;
    },
    onCopyClick() {
       this.$gtag.event('他人主页-钱包地址', { 'event_category': 'Click', 'event_label': '钱包地址' })
      var input = document.createElement("input");
      input.value =
          this.$chainName == "UPTICK EVM"
              ? this.bscAddress
              : this.$route.query.did;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);
    },
    onMediaClick(val) {
      window.open(val, "_blank");
    },
    avatarClick() {
      if (this.pageInfo.did == this.$store.state.did) {
        this.$router.push({name: "Fans"});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  vertical-align: middle;

  img,
  span {
    display: inline-block;
  }

  span {
    color: #270645 !important;
  }
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.pading-gap {
  padding: 0;
}

.contain {
  padding: 0;
  margin: 0;

  .banner {
    height: 301px !important;
    background-color: #f8f6fd;
    // margin-top: 31px;
    .img {
      height: 100%;
      width: 100%;
    }
  }

  .infocard {
    position: relative;
    top: -50px;
    padding: 14px 0;

    .infoAvat {
      .avat {
        .unflowbox {
          width: 86px;
          height: 41px;
          border: solid 2px #1d42ff;
          border-radius: 20px;
          position: relative;
          top: 30px;
          left: 50px;

          .unflow {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 20px;
            background: #fff;
            font-weight: bold;;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .flowed {
          width: 121px;
          height: 41px;
          background-color: #1d42ff;
          border-radius: 20px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .isPcBtn {
          position: relative;
          top: 30px;
          left: 35px;
        }
      }

      .infos {
        .showtip {
          min-width: 997px;
        }

        .unflowbox1 {
          width: 86px;
          height: 41px;
          box-sizing: border-box;
          padding: 2px;
          background-image: linear-gradient(
                  0deg,
                  #d202fd 0%,
                  #a538fe 26%,
                  #776eff 51%,
                  #01facd 100%
          );
          border-radius: 20px;
          margin: 0 auto;

          .unflow {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 20px;
            background: #fff;
            font-weight: bold;;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .flowed {
          width: 121px;
          height: 41px;
          background-color: #270645;
          border-radius: 20px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    	 .explains {
							width: 110px;
							height: 30px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 105px;
							right: 0px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}

    .name {
      font-weight: bold;;
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .no_copy {
      .No {
        font-family: Helvetica;
        font-size: 11px;
        color: #270645;
        padding-top: 4px;
      }

      .copy {
        width: 13px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        cursor: pointer;
      }

      .click {
        margin-top: 5px;
      }
    }

    .icon_url {
      margin: 25px 0 -5px;

      .icon-box {
        display: inline-block;
        word-wrap: break-word;
        word-break: break-all;
        vertical-align: middle;
        margin: 0 24px 0 0;

        span {
          display: inline;
          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #000000;
        }

        img {
          display: inline;
          width: 22px;
          height: 22px;
          margin: 0 8px 0 0;
        }
      }

      .media {
        cursor: pointer;
      }
    }

    .desc {
    
      // overflow: hidden;
      // font-size: 13px;
      // white-space: normal !important;
      // text-overflow: ellipsis;
      // word-wrap: break-word;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
    margin-top: 12px;
		max-width: 842px;
		font-family: Helvetica;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #270645;
    }

    .ingbox {
      max-width: 996px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;

      .box {
        height: 300px;
        // width:auto;
        margin-right: 5px;

        img {
          width: auto;
          height: 300px;
          display: block;
          // object-fit: contain;
        }
      }
    }

    .followers {
      padding: 0 46px;
    }

    .num {
      font-weight: bold;;
      font-size: 15px;
      color: #270645;
      font-weight: bold;
    }

    .sub {
      font-family: Helvetica;
      font-size: 14px;
      color: #766983;
    }

    .line {
      border-bottom: solid 1px #e3e3e3;
      margin: 18px 0;
    }

    .follow-btn {
      width: auto;
      //   height: 41px;
      //   padding: 0 15px;
      //   color: #270645;
      //   font-size: 15px;
      //   border-radius: 20px;
      margin: 0 auto;
    }

    .footer {
      .avatar-name {
        font-weight: bold;;
        font-size: 14px;
        color: #270645;
        font-weight: bold;
        padding-right: 14px;
      }

      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        background: white;
        padding: 2px;
        border-radius: 50%;
        // cursor: pointer;
      }
    }
  }

  &.mobile {
    width: 100%;

    .banner {
      // width: 320px;
      height: 180px;
      // margin-left: -40px;
      // margin:31px 0 0 0 ;
      background-color: #c9c9c9;

      .img {
        height: 100%;
      }
    }

    .infocard {
      .infoAvat {
        width: 100%;
          .avat {
             margin-left: 25% !important;
        .unflowbox {
          width: 86px;
          height: 41px;
          border: solid 2px #1d42ff;
          border-radius: 20px;
          position: relative;
          top: 150px;
          left: -150px;

          .unflow {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 20px;
            background: #fff;
            font-weight: bold;;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .flowed {
          width: 121px;
          height: 41px;
          background-color: #1d42ff;
          border-radius: 20px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .isPcBtn {
          position: relative;
          top: 150px;
          left: -150px;
        }
      }

        .avat {
          margin-top: -30px;
          margin-left: calc(50% - 95.5px) !important;
          .isPcBtn {
              left: -155px;
          }
        }

        .infos {
          text-align: center;

          .showtip {
            min-width: 100px;
          }

          span {
            text-align: center;
          }
          .name{
            width: 100%;
          }
        }
      }

      .desc {
        width: 100%;
      }

      .ingbox {
        margin-top: 25px;
        margin-bottom: 30px;
        // width:100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        .box {
          height: 350px;
          // width:350px;
          img {
            //width:100%;
            width: auto;
            height: 100%;
            // object-fit: contain;
          }
        }
      }

      .icon_url {
        margin: 25px 0 24px;
        text-align: left;

        .icon-box {
          display: inline-block;
          word-wrap: break-word;
          word-break: break-all;
          vertical-align: middle;
          margin: 0 24px 0 0;

          span {
            display: inline;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #000000;
          }

          img {
            display: inline;
            width: 22px;
            height: 22px;
            margin: 0 8px 0 0;
          }
        }

        .media {
          cursor: pointer;
        }
      }
    }

    .follow {
      flex-direction: row !important;
      margin: auto;
      margin-top: 10px;
    }

    .flowed {
      margin-top: 50px;
      margin-right: 32px;
    }
    .unflowbox {
      margin-top: 50px;
      margin-left: 20px;
      margin-right: 47px;
    }
    .footer{
      margin-left: 100px;
      margin-top: 10px;
    }
  }
}
</style>
